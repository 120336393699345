export default {
  'original_password': 'รหัสผ่านเดิม',
  'new_password': 'รหัสผ่านใหม่',
  'enter_the_original_password': 'กรุณาใส่รหัสผ่านเดิม',
  'enter_the_new_password_(enter_8_or_more_characters)': 'กรุณาใส่รหัสผ่านใหม่ (ใส่ 8 หรือมากกว่าหลัก)',
  'confirm_password': 'ยืนยันรหัสผ่าน',
  're_enter_the_new_password': 'กรุณาใส่รหัสผ่านใหม่อีกครั้ง',
  'confirm_modification': 'ยืนยันการเปลี่ยนแปลง',
  'enter_new_password_enter_6_digit_number': 'กรุณาใส่รหัสผ่านใหม่ (ใส่หลักการณ์ตัวเลข 6 หลัก)',
  'recharge_amount': 'จำนวนเงินที่ต้องการเติมเงิน',
  'reason_for_rejection': 'เหตุผลของการปฏิเสธ',
  'time': 'เวลา',
  'in_review': 'รอการตรวจสอบ',
  'completed': 'ดำเนินการสําเร็จ',
  'rejected': 'ตรวจสอบล้มเหลว',
  'current_browser_does_not_support_copy': 'เว็บเพลิงตอนนี้ไม่สนับสนุนการคัดลอก',
  'copy_successful': 'คัดลอกเรียบร้อย',
  'home': 'หน้าแรก',
  'liquidity_pool': 'หลวงน้ำ',
  'me': 'ของฉัน',
  'recharge': 'เติมเงิน',
  'withdraw': 'ถอนเงิน',
  'buy_second_contract': 'รายการสั่งซื้อ',
  'settle_second_contract': 'ผลรับที่ได้จากคำสั่งซื้อ',
  'buy_lever': 'ซื้อลเวอร์',
  'settle_lever': 'จัดสรรลเวอร์',
  'stake_lp': 'ทำการลงทุน LP',
  'earnings_lp': 'การความรวย LP',
  'settlement_lp': 'การจัดสรร LP',
  'admin': 'เติมเงิน',

  'withdraw_amount': 'จำนวนที่ต้องการถอน',
  'withdrawal_address': 'ที่อยู่การถอน',
  'rejection_reason': 'เหตุผลการปฏิเสธ',
  'under_review': 'รอการตรวจสอบ',
  'invitation_code': 'รหัสคำเชิญ',
  // 'invitation_code': 'รหัสเชิญ',
  'balance': 'เงินที่มีอยู่',
  'today': 'วันนี้',
  'deposit': 'เติมเงิน',
  'deposit_bill': 'รายการเติมเงิน',
  'real_name_authentication': 'ยืนยันชื่อนามสกุลที่ถูกต้อง',
  'billing_history': 'ประวัติการชำระเงิน',
  'second_contract_order': 'คำสั่งซื้อที่สอง',
  'liquidity_pool_order': 'คำสั่งซื้อสภาพคล่อง',
  'my_team': 'ทีมของฉัน',
  'change_password': 'เปลี่ยนรหัสผ่าน',
  'logout': 'ออกจากระบบ',
  'cancel': 'ยกเลิก',
  'document_type': 'ประเภทเอกสาร',
  'real_name': 'ชื่อและนามสกุลผู้ถือบัตร',
  'please_enter_real_name': 'กรุณากรอกชื่อและนามสกุล',
  'please_enter_document_number': 'กรุณากรอกเลขที่เอกสาร',
  'document_number': 'เลขที่เอกสาร',
  'document_photo': 'รูปภาพเอกสาร',
  'front': 'หน้า',
  'back': 'หลัง',
  'submit': 'ยืนยัน',
  'certification_passed': 'ผ่านการอนุมัติ',
  'identity_card': 'บัตรประชาชน',
  'passport': 'หนังสือเดินทาง',
  'login_password': 'รหัสผ่านเข้าระบบ',
  'transaction_password': 'รหัสถอนเงิน',
  'low': 'ต่ำ',
  'high': 'สูง',
  'open': 'เปิด',
  'close': 'ปิด',
  'buy_rise': 'ซื้อขึ้น',
  'buy_fall': 'ซื้อลง',
  'return_rate': 'อัตราการคืน',
  'capital': 'เงินทุน',
  'is_leverage': 'มีส่วนลดรายได้',
  'leverage_ratio': 'ส่วนลดรายได้',
  'amount': 'จำนวน',
  'available_amount': 'จำนวนที่มีอยู่',
  'expected_return': 'ผลกำไร',
  'transaction_fee': 'ค่าธรรมเนียมการแลกเปลี่ยน',
  'confirmation': 'ยืนยัน',
  'price': 'ราคา',
  'direction': 'การกระทำ',
  'rise': 'ขึ้น',
  'fall': 'ลดลง',
  'enter_payment_password': 'ใส่รหัสผ่านการชำระเงิน',
  'in_progress': 'กำลังดำเนินการ',
  'final_price': 'ราคาสุดท้าย',
  'leverage': 'ส่วนลดรายได้',
  'handling_fee': 'ค่าธรรมเนียมการจัดการ',
  'expected_profit_loss': 'ความคาดหวังที่แลกเปลี่ยนที่เกิน',
  'creation_time': 'เวลาที่สร้าง',
  'no_more': 'ไม่มีเพิ่มเติม',
  'has_ended': 'ดำเนินการเสร็จสิ้น',
  'actual_profit_and_loss': 'ผลการแลกเปลี่ยนที่แท้จริง',
  'end_time': 'เวลาที่สิ้นสุด',
  'billing_record': 'บันทึกการเงิน',
  'currency_pair': 'สูตรเงิน',
  'latest_price': 'ราคาล่าสุด',
  'price_change_percentage': 'เติมเติมอัตราเพิ่มเติมราคา',
  'staking_liquidity_pool': 'รูปแบบสภาพคล่อง',
  'participate_in_staking': 'เข้าร่วม',
  'current_total_staking': 'รวมทั้งหมดที่เล่น',
  'cumulative_rewards': 'รางวัลที่รวม',
  'add_liquidity': 'เพิ่มเติมเงิน',
  'daily_yield_rate': 'อัตราการแลกเปลี่ยนต่อวัน',
  'staking_limit': 'จำนวนที่สามารถเล่นได้',
  'day': 'วัน',
  'staking_time': 'เวลาที่เล่น',
  'input_staking_amount': 'กรอกจำนวนที่เล่น',
  'staking_amount': 'จำนวนที่เล่น',
  'total_return_rate': 'อัตราการคืนที่รวม',
  'confirm': 'ยืนยัน',
  'liquidity_pool_staking_order': 'การสั่งเล่นในกลุ่มเงิน',
  'daily_profit': 'ผลการแลกเปลี่ยนต่อวัน',
  'due_date': 'วันที่ต้องการ',
  'order_id': 'เลขที่สั่งซื้อ',
  'expiration_time': 'เวลาหมดอายุ',
  'cancel_order': 'ยกเลิกสั่งซื้อ',
  'confirm_cancellation': 'ยืนยันการยกเลิก',
  'penalty_rate': 'อัตราค่าปรับ',
  'expected_arrival_amount': 'จำนวนที่คาดหวังที่จะมา',
  'login_account': 'บัญชีของคุณ',
  'input_email_account': 'เข้าระบบด้วยอีเมล',
  'email': 'อีเมล',
  'password': 'รหัสผ่าน',
  'input_login_password': 'เข้าระบบด้วยรหัสผ่าน',
  'login': 'เข้าระบบ',
  'register_now': 'ลงทะเบียนเลย',
  'no_account_yet': 'ยังไม่มีบัญชี',
  'account': 'บัญชี',
  'invitation_time': 'เวลาที่มีคำเชิญ',
  'level': 'ระดับ',
  'please_select_withdrawal_currency': 'เลือกเงินที่ต้องการถอน',
  'withdrawal_amount': 'จำนวนที่ต้องการถอน',
  'please_enter_withdrawal_amount': 'กรุณากรอกจำนวนที่ต้องการถอน',
  'please_enter_withdrawal_address': 'กรุณากรอกที่อยู่ที่ต้องการถอน',
  'please_enter_transaction_password': 'กรุณากรอกรหัสถอนเงิน',
  'estimated_actual_arrival': 'การเข้าถึงที่คาดกัน',
  'please_select_recharge_currency': 'เลือกเงินที่ต้องการเชื่อมโยง',
  'recharge_address': 'ที่อยู่ของการเชื่อมโยง',
  'please_enter_recharge_amount': 'กรุณากรอกจำนวนที่ต้องการเชื่อมโยง',
  'please_enter_recharge_hash': 'กรุณากรอกรหัสหลักของการเชื่อมโยง',
  'recharge_hash': 'รหัสหลักของการเชื่อมโยง',
  'upload_recharge_proof_payment_screenshot': 'หลักฐานการโอน',
  "please_authenticate_first": "กรุณายืนยันตัวตนของคุณก่อน",
  "create_account": "สร้างบัญชี",
  "verification_code": "รหัสยืนยัน",
  "enter_verification_code": "กรอกรหัสยืนยัน",
  "get_verification_code": "ขอรหัสยืนยัน",
  "funds_password": "รหัสความปลอดภัย",
  "register": "ยืนยัน",
  // "register": "ลงทะเบียน",
  "set_funds_password_6_digit_number": "ตั้งรหัสความปลอดภัย (เลขที่ 6 หลัก)",
  "set_login_password_input_8_plus_characters": "ตั้งรหัสผ่านเข้าระบบ (ใส่ 8 หลักขาดขึ้น)",
  "please_enter_email": "กรุณากรอกอีเมลของคุณ",
  "announcement": "ประกาศ",
  "announcement_details": "รายละเอียดประกาศ",
  "times": "ครั้ง",
  "the_purchase_period_has_ended_please_make_a_new_selection": "ขั้นตอนการซื้อจบแล้ว กรุณาทำการเลือกใหม่",

  "index": {
    button: "เริ่มการตลาด",
    banTip: "กระจายพอร์ตการลงทุนของคุณด้วยการซื้อขายทองคำแท่ง แพลตฟอร์มที่ครอบคลุมของเรามีบริการซื้อขายทองคำและเงินครบวงจร",
    tip1: "ทองคำและเงินสามารถกระจายพอร์ตการลงทุนและป้องกันความเสี่ยงจากการลงทุนได้",
    tip2: "ผู้ซื้อขายสามารถทำกำไรในระยะสั้นหรือระยะยาวจากตลาดทองคำและเงิน",
    tip3: "เลเวอเรจแบบยืดหยุ่นช่วยให้คุณสามารถซื้อและขายทองคำหรือเงินด้วยจำนวนเงินที่น้อยลง",
    tip4: "การทำธุรกรรมนี้มีความยุติธรรม ชัดเจน และเปิดเผยอย่างแน่นอน!",
  },
  "newHome": {
    Cryptos: "เครดิตเว็บ",
    Forex: "ตลาดหลักทรัพย์",
    Gold: "ทอง",
    futures: "ทองทอน",
  },
  "newPool": {
    StartStaking: "เริ่มการแทนทาน",
  },
  "newMe": {
    balance: "เงินที่มีอยู่ในบัญชี",
    saveQr: "บันทึกรูป QR",
    TopUp: "รางวัลจากการเติมเงิน",
  },
  newLevel: "ระดับ",
  Cumulative: "ยอดทั้งหมดที่มีอยู่",
  levelSets: "เติมเงินเพื่ออัปเกรด",
  kefu: "ติดต่อทานการลูกค้าเพื่อรับ",
  levelInfo: {
    infoLv1: "เปิดให้เชื่อมโยง VIP สำหรับการเติมเงินและการถอน",
    infoLv2: "เปิดให้รับเงินโลก VIP ประกอบด้วยค่าบริการเดือนละ {number}",
    infoLv3: "ได้รับรายได้ที่เก็บไว้และมีรายได้แต่ละวันที่เก็บไว้อยู่ที่รายได้เดิมทาน 0.001 จากบัญชีเงินทุนของเว็บไซต์",
    toUpUsd: "เป้าหมายการเติมเงินของ {name}"
  },
  News: "ข่าว",

  StayTuned: "รอดูต่อไป",
  develop: "การพัฒนากำลังดำเนินการ กรุณารอดูต่อไป ~ ~",
  "赠送": "ได้รับ",
  "大礼包": "รางวัล {name}",
  "联系客服领取": "ติดต่อฝ่ายบริการ",

  meCard: "บัญชีธนาคารของฺฉัน",
  cardAdmin:"การจัดการบัตรธนาคาร",
  newCard: {
    cardName:'ชื่อธนาคาร',
    cardNamePla:'กรุณากรอกชื่อธนาคาร',
    number:'บัญชีผู้รับเงิน',
    numberPla:'กรุณากรอกหมายเลขบัญชีผู้รับเงิน',
    name:'ชื่อผู้รับเงิน',
    namePla:'กรุณากรอกชื่อผู้รับเงิน',
    save:'ยืนยัน',
    balance:"ยอดเงินในบัญชี",
    cardInfos:'ข้อมูลบัญชีธนาคาร',
    customer_service:'กรุณาติดต่อฝ่ายบริการลูกค้า，เวลาถอนเงิน: 10.00 - 23.00 น',
    addCardInfo:'โปรดเพิ่มข้อมูลบัญชีธนาคารก่อน'
  },
  status:"สถานภาพ",
  Order:"คำสั่ง",

  Phone:'เบอร์โทรศัพท์',
  'input_phone_account': 'ป้อนหมายเลขโทรศัพท์',
  "选择国家或地区":"เลือกประเทศหรือภูมิภาค",
  "请输入搜索关键词":"กรุณาใส่คำที่ต้องการค้นหา",




}
